@import "../mixins";

.tabs-header {
  .nav {
    position: static;
    width: auto;
    max-width: none;
    height: auto;
    max-height: none;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transform: none;
    margin-bottom: 0;
    padding-left: 0; // Override default ul/ol
    list-style: none;
    @include clearfix;

    > li {
      position: relative;
      display: block;

      > a {
        position: relative;
        display: block;
        padding: 10px 15px;
        &:hover,
        &:focus {
          text-decoration: none;
          background-color: #eee;
        }
      }

      // Disabled state sets text to gray and nukes hover/tab effects
      &.disabled > a {
        color: #777;

        &:hover,
        &:focus {
          color: #777;
          text-decoration: none;
          background-color: transparent;
          cursor:  not-allowed;
        }
      }
    }

    // Open dropdowns
    .open > a {
      &,
      &:hover,
      &:focus {
        background-color: #eee;
        border-color: #3097D1;
      }
    }


    // Prevent IE8 from misplacing imgs
    //
    // See https://github.com/h5bp/html5-boilerplate/issues/984#issuecomment-3985989
    > li > a > img {
      max-width: none;
    }
  }
}


// Tabs
// -------------------------

// Give the tabs something to sit on
.nav-tabs {
  border-bottom: 1px solid #ddd;
  > li {
    float: left;
    // Make the list-items overlay the bottom border
    margin-bottom: -1px;

    // Actual tabs (as links)
    > a {
      margin-right: 2px;
      line-height: 1.428571429;
      border: 1px solid transparent;
      border-radius: 4px 4px 0 0;
      &:hover {
        border-color: #eee #eee #ddd;
      }
    }

    // Active state, and its :hover to override normal :hover
    &.active > a {
      &,
      &:hover,
      &:focus {
        color: #555;
        background-color: #f1f1f7;
        border: 1px solid #ddd;
        border-bottom-color: transparent;
        cursor: default;
      }
    }
  }
}

.tabs-details {
  padding: 20px 0;
}