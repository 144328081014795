.tabs-header .nav {
  position: static;
  width: auto;
  max-width: none;
  height: auto;
  max-height: none;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  transform: none;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.tabs-header .nav:before, .tabs-header .nav:after {
  content: " ";
  display: table;
}

.tabs-header .nav:after {
  clear: both;
}

.tabs-header .nav > li {
  position: relative;
  display: block;
}

.tabs-header .nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.tabs-header .nav > li > a:hover, .tabs-header .nav > li > a:focus {
  text-decoration: none;
  background-color: #eee;
}

.tabs-header .nav > li.disabled > a {
  color: #777;
}

.tabs-header .nav > li.disabled > a:hover, .tabs-header .nav > li.disabled > a:focus {
  color: #777;
  text-decoration: none;
  background-color: transparent;
  cursor: not-allowed;
}

.tabs-header .nav .open > a, .tabs-header .nav .open > a:hover, .tabs-header .nav .open > a:focus {
  background-color: #eee;
  border-color: #3097D1;
}

.tabs-header .nav > li > a > img {
  max-width: none;
}

.nav-tabs {
  border-bottom: 1px solid #ddd;
}

.nav-tabs > li {
  float: left;
  margin-bottom: -1px;
}

.nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.428571429;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
}

.nav-tabs > li > a:hover {
  border-color: #eee #eee #ddd;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  color: #555;
  background-color: #f1f1f7;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
  cursor: default;
}

.tabs-details {
  padding: 20px 0;
}

.btn-link {
  color: #333;
  transition: .3s ease;
  transition-delay: 0s;
}

.btn-link:hover {
  color: #ffc621;
}

.mt-1 {
  margin-top: 10px;
}

.mt-2 {
  margin-top: 15px;
}

.d-block {
  display: block;
}

.lowercase {
  text-transform: none;
}

.search__history {
  cursor: pointer;
}

.languageDropdown .dropdown__link {
  text-transform: uppercase;
}

.ticket__arrow .ticket__dot:first-of-type {
  display: none;
}

.ticket__arrow .ticket__dot:last-child {
  display: none;
}

.makingOrder:before {
  content: "";
  background: #fff;
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  position: absolute;
  opacity: .8;
}

.agree {
  margin-top: 20px;
}

.agree a {
  color: #f7bf16;
}
